import React, { Component } from "react"
import t from "prop-types"

class Modal extends Component {
  static propTypes = {
    className: t.string,
    children: t.element.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeClass: "",
      active: false,
      hasEventListeners: false,
    }
    this.hide = this.hide.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  show() {
    this.setState({
      activeClass: "active",
      active: true,
    })
    document.body.classList.add("modal-active");
    document.addEventListener("keydown", this.handleKeyDown);
  }

  hide() {
    this.setState({
      activeClass: "",
      active: false,
    })
    document.body.classList.remove("modal-active");
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.hide();
    }
  }

  render() {
    const { className, linkText, htmlContent } = this.props
    return (
      <React.Fragment>
        <button
          className={"wwc-modal__button wwc-modal__button--open"}
          onClick={this.show.bind(this)}
          aria-label="Open modal"
        >
          {linkText}
        </button>

        <div
          className={`wwc-modal ${className ? className : ''} ${this.state.activeClass}`}
          ref={el => (this.el = el)}
        >
          <div className={`container container--modal`}>
            <button
              className={"wwc-modal__button wwc-modal__button--close"}
              onClick={this.hide.bind(this)}
              aria-label="Close modal"
            ></button>

            {this.state.active &&
              <>
                {this.props.children}
                <div className="modal__content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
              </>
            }
          </div>

        </div>
      </React.Fragment >
    )
  }
}

export default Modal
