import React, { Component } from "react"
import { graphql } from "gatsby"
import t from "prop-types"

class SocialIcons extends Component {
  static propTypes = {
    data: t.arrayOf(
      t.shape({
        social: t.shape({
          brands: t.shape({
            brand: t.string,
            shareUrl: t.string,
          }).isRequired,
        }),
      })
    ).isRequired,
  }

  render() {
    const { data } = this.props
    return (
      <ul className={`social__icons-list`}>
        {data.map((item, index) => {
          return (
            <li key={`social__list-item-${index}`}>
              <a
                href={item.shareUrl}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`social-icon-${item.brand}`}
              >
                {<i className={`fab fa-${item.brand}`}></i>}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}

export const query = graphql`
  fragment SocialData on SiteJson {
    site {
      social {
        shareText
        brands {
          brand
          shareUrl
        }
      }
    }
  }
`

export default SocialIcons
