import React from "react"
import t from "prop-types"

class Accordion extends React.Component {
  static propTypes = {
    title: t.string.isRequired,
    children: t.node.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      activeClass: "",
    }
  }

  toggleActive(e) {
    e.preventDefault()
    if (this.state.activeClass === "accordion__item--active") {
      this.setState({ activeClass: "" })
    } else {
      this.setState({ activeClass: "accordion__item--active" })
    }
  }

  render() {
    const { title, children } = this.props

    return (
      <>
        <div className={`accordion__item ${this.state.activeClass}`}>
          <button
            className="accordion__toggle"
            onClick={this.toggleActive.bind(this)}
            aria-label="FAQ Toggle"
          >
            {title}
            <div className="accordion__toggle-icon-container">
              <span className="accordion__toggle-icon"></span>
            </div>
          </button>

          <div className="accordion__description">{children}</div>
        </div>
      </>
    )
  }
}

export default Accordion
