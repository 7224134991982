import React, { Component } from "react"
import t from "prop-types"

import Row from "../../wwc/layout/Row/Row"
import Column from "../../wwc/layout/Column/Column"
import { Link, graphql } from "gatsby"

class SecondaryNavigation extends Component {
  static propTypes = {
    classes: t.string,
    data: t.shape({
      links: t.arrayOf(
        t.shape({
          label: t.string,
          url: t.string,
          anchor: t.string,
        })
      ),
    }).isRequired,
  }

  onNavItemClick = (e, anchor) => {
    var el = document.getElementById(anchor)
    if (el) {
      el.classList.add("scrolled-to")

      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top)

      e.preventDefault()

      const targetID = el.id
      const targetAnchor = document.getElementById(targetID)
      if (!targetAnchor) return

      const originalTop = distanceToTop(targetAnchor)
      let primaryNavHeight = document.querySelector(".wwc-header").offsetHeight
      let secondaryNavHeight = document.querySelector(
        "#section-secondary-nav .outer__container"
      ).offsetHeight
      let adjustedTop = originalTop - primaryNavHeight - secondaryNavHeight

      if (window.document.documentMode || /Edge/.test(navigator.userAgent)) {
        // WON-146 - ie11/edge fix
        window.scrollTo(
          0,
          targetAnchor.offsetTop - primaryNavHeight - secondaryNavHeight
        )
      } else {
        window.scrollBy({ top: adjustedTop, left: 0, behavior: "smooth" })
      }
    }
    this.toggleMenuMobile(e)
  }

  componentDidMount() {
    /* Feature detection: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners */
    let passiveIfSupported = false
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function() {
            passiveIfSupported = { passive: false }
            return null
          },
        })
      )
    } catch (err) {}

    window.addEventListener(
      "scroll",
      this.onScroll.bind(this),
      passiveIfSupported
    )
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll.bind(this))
  }

  onScroll = () => {
    if (this.secondaryNavSection !== null) {
      let headerEle = document.querySelector(".wwc-header")
      if (
        window.pageYOffset + headerEle.offsetHeight >
        this.secondaryNavSection.offsetTop
      ) {
        this.secondaryNavSection.classList.add("secondary__nav--scroll")
      } else {
        this.secondaryNavSection.classList.remove("secondary__nav--scroll")
      }
    }

    this.handleActiveNavItem()
  }

  handleActiveNavItem = () => {
    const links = document.querySelectorAll(".secondary-nav__item")
    const sections = document.querySelectorAll(".section__pom-prize")

    for (var i = 0; i < sections.length; i++) {
      if (sections[i].offsetTop > window.scrollY) {
        if (typeof links[i] !== "undefined") {
          // add active to current item
          if (
            window.scrollY + window.innerHeight / 2 >
            this.eleTopPosition(sections[i])
          ) {
            if (!links[i].classList.contains("secondary-nav__item--active")) {
              let activeNavItems = document.querySelectorAll(
                ".secondary-nav__item--active"
              )
              if (activeNavItems !== null) {
                for (var j = 0; j < activeNavItems.length; j++) {
                  activeNavItems[j].classList.remove(
                    "secondary-nav__item--active"
                  )
                }
              }
              links[i].classList.add("secondary-nav__item--active")
              if (this.buttonText) {
                this.buttonText.innerHTML = links[i].querySelector(
                  "a"
                ).innerHTML
              }
            }
          }
        }
      }
    }
  }

  eleTopPosition = ele => {
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = ele.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top
    return offset
  }

  toggleMenuMobile = e => {
    e.preventDefault()
    this.secondaryNavOuterContainer.classList.toggle(
      "outer__container--mobile-active"
    )
  }

  render() {
    const { data } = this.props
    return (
      <>
        <section
          id="section-secondary-nav"
          ref={ref => {
            this.secondaryNavSection = ref
          }}
        >
          <div
            className="outer__container"
            ref={ref => {
              this.secondaryNavOuterContainer = ref
            }}
          >
            <div className="container">
              <Row>
                <Column>
                  <ul className="secondary-nav__container">
                    <li className="secondary-nav__mobile-toggle">
                      <button
                        onClick={this.toggleMenuMobile}
                        ref={ref => {
                          this.buttonText = ref
                        }}
                      >
                        {data.mobileTitle}
                      </button>
                      <a
                        onClick={this.toggleMenuMobile}
                        className="triangle"
                      ></a>
                    </li>
                    {data.links.map((item, index) => {
                      return (
                        <li
                          className="secondary-nav__item"
                          key={`secondary-nav__links--item-${index}`}
                        >
                          <Link
                            to={item.url}
                            title={item.label}
                            onClick={e => this.onNavItemClick(e, item.anchor)}
                            activeClassName="active"
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </Column>
              </Row>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const query = graphql`
  fragment SecondaryNavigationData on PomPaydayJson {
    secondaryNavigation {
      mobileTitle
      links {
        label
        url
        anchor
      }
    }
  }
`

export default SecondaryNavigation
