/* eslint-disable no-unused-expressions */
import React from "react"
import { graphql } from "gatsby"

import Section from "../components/wwc/layout/Section/Section"
import Row from "../components/wwc/layout/Row/Row"
import Column from "../components/wwc/layout/Column/Column"
import Text from "../components/wwc/text/Text/Text"
import Title from "../components/wwc/text/Title/Title"
import Hero from "../components/wwc/media/Hero/Hero"
import SecondaryNavigation from "../components/app/SecondaryNavigation/SecondaryNavigation"
import GatsbyImage from "../components/gatsby/image"
import WhatWeLookFor from "../components/app/WhatWeLookFor/WhatWeLookFor"
import SocialIcons from "../components/wwc/media/SocialIcons/SocialIcons"
import Accordion from "../components/app/Accordion/Accordion"
import Timeline from "../components/app/Timeline/Timeline"
import Modal from "../components/wwc/layout/Modal/Modal"
import OfferPrize from "../components/app/Offer/OfferPrize"
import { Helmet } from "react-helmet"

const PomChallenge = ({ data, location, ...props }) => {
  const {
    hero,
    winners,
    secondaryNavigation,
    challenge,
    offer,
    timeline,
    judges,
    faq,
    metaData,
    submitApplication,
  } = data.pomPaydayJson
  const { whatWeLookFor } = data.whatWeLookForJson
  const { site } = data.siteJson

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta property="og:description" content={metaData.description} />
      </Helmet>

      <Hero data={hero} classes={`hero__pom-prize`} />

      <Section id="section-winners" className="section__pom-winners">
        <Row>
          <Column className="winners__intro">
            <Title h2>{winners.title}</Title>
            <Text>{winners.description}</Text>
          </Column>
        </Row>

        {winners.winnerBlocks.map((item, index) => {
          return (
            <Row className="winners__item" key={`winners__item-${index}`}>
              <Column lg={6} className="winners__img">
                <GatsbyImage
                  imageName={item.img.imageName}
                  alt={item.img.alt}
                  styles={{ height: "100%" }}
                  className={``}
                />
              </Column>
              <Column lg={6} className="winners__info">
                <Title h5>{item.title}</Title>
                <Text>{item.text}</Text>
              </Column>
            </Row>
          )
        })}
      </Section>

      <SecondaryNavigation data={secondaryNavigation} />

      <Section id="section-challenge" className="section__pom-prize">
        <Row>
          <Column className="challenge__intro">
            <Title h2>{challenge.title}</Title>
            <Text>{challenge.description}</Text>
          </Column>

          <Column lg={6} className="challenge__items">
            {challenge.contentBlock.map((item, index) => {
              return (
                <div
                  className="challenge__item"
                  key={`challenge__item-${index}`}
                >
                  <Title h5>{item.title}</Title>
                  <Text>{item.description}</Text>
                </div>
              )
            })}
          </Column>

          <Column lg={6} className="challenge__image">
            <div className="gears__container">
              {challenge.gearsImages.map((item, index) => {
                return (
                  <div
                    className={`gear__item gear__item--${index}`}
                    key={`gear__item gear__item--${index}`}
                  >
                    <GatsbyImage
                      imageName={item.imageName}
                      alt={item.alt}
                      styles={{ width: item.width }}
                      className={`gear`}
                    />
                  </div>
                )
              })}
            </div>
          </Column>
        </Row>
      </Section>

      <WhatWeLookFor data={whatWeLookFor} />

      <Section id="section-offer" className="section__pom-prize">
        <Row>
          <Column lg={6} className="offer__intro offer__intro--outer">
            <div className="offer__intro offer__intro--inner">
              <Title h2>{offer.title}</Title>
              <Text>{offer.description}</Text>
            </div>
          </Column>
          <Column lg={6} className="offer__image">
            <OfferPrize data={offer} />
          </Column>
        </Row>
      </Section>

      <Section id="section-timeline" className="section__pom-prize">
        <Row>
          <Column className="timeline__intro">
            <Title h2>{timeline.title}</Title>
          </Column>

          <Column>
            <Timeline data={timeline} />
          </Column>
        </Row>
      </Section>

      <Section id="section-judges" className="section__pom-prize">
        <Row>
          <Column className="judges__intro judges__intro--outer">
            <div className="judges__intro judges__intro--inner">
              <Title h2>{judges.title}</Title>
              <Text>{judges.description}</Text>
            </div>
          </Column>
          {judges.contentBlock.map((item, index) => {
            return (
              <div
                className="judges__item col-6 col-md-4 col-lg-3" // exclude `col` class as it breaks position fixed in modals
                key={`judges__item-${index}`}
              >
                {item.image && (
                  <GatsbyImage
                    imageName={item.image.imageName}
                    alt={item.image.alt}
                    styles={{ width: "187px" }}
                  />
                )}

                {item.title && <Title h6>{item.title}</Title>}
                {item.personTitle && <Text>{`${item.personTitle},`}</Text>}
                {item.company && <Text>{item.company}</Text>}

                {item.modal && (
                  <Modal
                    linkText={item.modal.label}
                    htmlContent={item.modal.content}
                  >
                    {
                      <>
                        <div className="modal__header">
                          {item.image && (
                            <GatsbyImage
                              imageName={item.image.imageName}
                              alt={item.image.alt}
                              styles={{ width: "187px" }}
                            />
                          )}
                          <div className="modal__header-content">
                            {item.title && <Title h3>{item.title}</Title>}
                            {item.modal.modalTitle ? (
                              <Title h6>{`${item.modal.modalTitle}`}</Title>
                            ) : (
                              item.personTitle && (
                                <Title
                                  h6
                                >{`${item.personTitle}, ${item.company}`}</Title>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    }
                  </Modal>
                )}

                {item.link && (
                  <a
                    href={item.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.link.label}
                  </a>
                )}
              </div>
            )
          })}
        </Row>
      </Section>

      <Section id="section-faq" className="section__pom-prize">
        <Row>
          <Column>
            <Title h2>{faq.title}</Title>
            {faq.image && (
              <div className="faq__image-container">
                <GatsbyImage
                  imageName={faq.image.imageName}
                  alt={faq.image.alt}
                  styles={{ width: "378px" }}
                />
              </div>
            )}
            <ul className="faq__container">
              {faq.faqContentBlock.map((item, index) => {
                return (
                  <li className="faq__item" key={`faq__item-${index}`}>
                    <Accordion title={item.title}>
                      <ol>
                        {item.faqItemsContentBlock.map((item, index) => {
                          return (
                            <li key={`faq__item-inner--${index}`}>
                              <h3>{item.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></p>
                            </li>
                          )
                        })}
                      </ol>
                    </Accordion>
                  </li>
                )
              })}
            </ul>
          </Column>
        </Row>
      </Section>
      {/*
      <Section id="section-resources" className="section__pom-prize">
        <Row>
          <Column className="resources__intro">
            <Title h2>{resources.title}</Title>
          </Column>
          <Column>
            <ul className="resources__container">
              {
                resources.contentBlock.map((item, index) => {
                  return (
                    <li className="resources__item" key={`resources__item-${index}`}>
                      {item.link &&
                        <a href={item.link.url}>
                          <span className="resources__item-text">{item.link.label}</span>
                          {item.image &&
                            <div className="icon__download-container">
                              <GatsbyImage
                                className="icon__download-image--inactive"
                                imageName={item.image.imageName}
                                alt={item.image.alt}
                                styles={{ 'width': '19px' }} />
                              <GatsbyImage
                                className="icon__download-image--active"
                                imageName={item.imageActive.imageName}
                                alt={item.image.alt}
                                styles={{ 'width': '19px' }} />
                            </div>
                          }
                        </a>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </Column>
        </Row>
      </Section>
       */}

      <Section id="section-submit-application" className="section__pom-prize">
        {submitApplication.image && (
          <GatsbyImage
            className="application__image application__image--top-right"
            imageName={submitApplication.image.imageName}
            alt={submitApplication.image.alt}
            styles={{ width: "294px" }}
          />
        )}
        {/* <Row>
          <Column md={8}>
            <Title h4>{submitApplication.title}</Title>
          </Column>
          <Column className="cta__container" md={4}>
            <a
              href={submitApplication.link.url}
              className="btn__cta--primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              {submitApplication.link.label}
            </a>
          </Column>
        </Row> */}

        {submitApplication.image && (
          <GatsbyImage
            className="application__image application__image--bottom-left"
            imageName={submitApplication.image.imageName}
            alt={submitApplication.image.alt}
            styles={{ width: "294px" }}
          />
        )}
      </Section>

      <Section id="section-social" className="section__pom-prize">
        <Row>
          <Column className="social__container">
            <Text>{site.social.shareText}</Text>
            <SocialIcons data={site.social.brands} />
          </Column>
        </Row>
      </Section>
    </>
  )
}

export const query = graphql`
  query PomChallenge($locale: String) {
    siteJson(locale: { eq: $locale }) {
      ...SiteData
      ...SocialData
    }
    pomPaydayJson(locale: { eq: $locale }) {
      metaData {
        title
        description
      }
      winners {
        title
        description
        winnerBlocks {
          title
          text
          img {
            imageName
            alt
          }
        }
      }
      hero {
        title
        description
        img {
          imageName
          imageNameMobile
          alt
        }
      }
      ...SecondaryNavigationData
      challenge {
        title
        description
        contentBlock {
          title
          description
        }
        gearsImages {
          imageName
          alt
          width
        }
      }
      ...OfferDataQuery
      timeline {
        title
        contentBlock {
          title
          date
          description
          revealPercentage
        }
      }
      judges {
        title
        description
        contentBlock {
          title
          personTitle
          company
          image {
            imageName
            alt
          }
          modal {
            modalTitle
            label
            content
          }
        }
      }
      faq {
        title
        image {
          imageName
          alt
        }
        faqContentBlock {
          title
          faqItemsContentBlock {
            title
            description
          }
        }
      }
      submitApplication {
        title
        link {
          label
          url
        }
        image {
          imageName
          alt
        }
      }
    }
    whatWeLookForJson(locale: { eq: $locale }) {
      ...WhatWeLookForData
    }
  }
`

export default PomChallenge
