import React from "react"
import Title from "../../wwc/text/Title/Title"
import Text from "../../wwc/text/Text/Text"
import GatsbyImage from "../../gatsby/image"
import t from "prop-types"

class Timeline extends React.Component {
  static propTypes = {
    data: t.shape({
      title: t.string,
      contentBlock: t.arrayOf(
        t.shape({
          title: t.string,
          date: t.string,
          description: t.string,
          revealPercentage: t.string,
        })
      ),
    }).isRequired,
  }

  componentDidMount() {
    // init elements
    this.timelineContainer = document.querySelector(".timeline__container")
    this.timelineItems = document.querySelectorAll(".timeline__item")

    this.initTimelineSvg()
    // this.initTimelineSvg(this.svgTablet);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.timelineScroll)
  }

  animateTimelineItems = () => {
    for (var i = 0; i < this.timelineItems.length; i++) {
      if (
        this.getEleViewportPercentage(this.timelineContainer) >=
        this.timelineItems[i].getAttribute("data-reveal-percent")
      ) {
        this.timelineItems[i].classList.add("timeline__item--active")
      } else {
        this.timelineItems[i].classList.remove("timeline__item--active")
      }
    }
  }

  initTimelineSvg = () => {
    /* Feature detection: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners */
    let passiveIfSupported = false
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function() {
            passiveIfSupported = { passive: false }
            return null
          },
        })
      )
    } catch (err) {}

    // TODO: refactor for more dynamic code
    // Get a reference to the <path>
    this.pathDesktop = this.svgDesktop
    this.pathTablet = this.svgTablet
    this.pathMobile = this.svgMobile

    this.pathDesktopLength = this.pathDesktop.getTotalLength()
    this.pathTabletLength = this.pathTablet.getTotalLength()
    this.pathMobileLength = this.pathMobile.getTotalLength()

    // Offset the dashes so the it appears hidden entirely
    this.pathDesktop.style.strokeDashoffset = this.pathDesktopLength
    this.pathTablet.style.strokeDashoffset = this.pathTabletLength
    this.pathMobile.style.strokeDashoffset = this.pathMobileLength

    this.pathDesktop.getBoundingClientRect() // Jake Archibald says so: https://jakearchibald.com/2013/animated-line-drawing-svg/
    this.pathTablet.getBoundingClientRect() // Jake Archibald says so: https://jakearchibald.com/2013/animated-line-drawing-svg/
    this.pathMobile.getBoundingClientRect() // Jake Archibald says so: https://jakearchibald.com/2013/animated-line-drawing-svg/

    window.addEventListener("scroll", this.timelineScroll, passiveIfSupported)
  }

  timelineScroll = () => {
    if (this.elementInViewport(this.timelineContainer)) {
      this.animateTimelineSvgOnScroll()
      this.animateTimelineItems()
    }

    if (
      document.querySelectorAll(".timeline__item:not(.timeline__item--active)")
        .length === 0
    ) {
      window.removeEventListener("scroll", this.timelineScroll)
    }
  }

  animateTimelineSvgOnScroll = () => {
    /* Animate SVG dashed path */
    this.scrollPercentage = this.getEleViewportPercentage(
      this.timelineContainer
    )

    // Length to offset the dashes
    this.drawDesktopLength = this.pathDesktopLength * this.scrollPercentage
    this.drawTabletLength = this.pathTabletLength * this.scrollPercentage
    this.drawMobileLength = this.pathMobileLength * this.scrollPercentage

    // Draw in reverse
    this.pathDesktop.style.strokeDashoffset =
      this.pathDesktopLength - this.drawDesktopLength
    this.pathTablet.style.strokeDashoffset =
      this.pathTabletLength - this.drawTabletLength
    this.pathMobile.style.strokeDashoffset =
      this.pathMobileLength - this.drawMobileLength

    // When complete, remove the dash array, otherwise shape isn't quite sharp
    if (this.scrollPercentage >= 0.99) {
      this.pathDesktop.style.strokeDasharray = "none"
      this.pathTablet.style.strokeDasharray = "none"
      this.pathMobile.style.strokeDasharray = "none"
    } else {
      this.pathDesktop.style.strokeDasharray = `${this.pathDesktopLength}`
      this.pathTablet.style.strokeDasharray = `${this.pathTabletLength}`
      this.pathMobile.style.strokeDasharray = `${this.pathMobileLength}`
    }
  }

  getEleViewportPercentage = element => {
    this.scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

    this.percentVisible =
      ((this.getTopOffset(element).top - window.scrollY - window.innerHeight) /
        element.offsetHeight) *
      -1.2 // math is backwards so transforming it into position numer with -1

    if (this.percentVisible <= 1 && this.percentVisible > 0) {
      return this.percentVisible
    } else if (this.percentVisible <= 0) {
      return 0
    } else {
      return 1
    }
  }

  getTopOffset = el => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  elementInViewport = el => {
    var top = el.offsetTop
    var left = el.offsetLeft
    var width = el.offsetWidth
    var height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    )
  }

  render() {
    const { data } = this.props
    return (
      <>
        <div className="timeline__svg timeline__svg--desktop">
          <svg
            width="1020"
            height="247"
            viewBox="0 0 1020 247"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={ref => {
                this.svgDesktop = ref
              }}
              d="M131 1L785.846 2H1018.44L1019 169.5H1V246.5H892"
              stroke="#ED1C23"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
            <path
              d="M131 1L785.846 2H1018.44L1019 169.5H1V246.5H892"
              stroke="#E5F4FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
          </svg>
        </div>

        <div className="timeline__svg timeline__svg--tablet">
          <svg
            width="530"
            height="608"
            viewBox="0 0 530 608"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={ref => {
                this.svgTablet = ref
              }}
              d="M143 1H529.5V159.5H1V200.5H529.5V351H1V399H529.5V566.5H1V607H390"
              stroke="#ED1C23"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
            <path
              d="M143 1H529.5V159.5H1V200.5H529.5V351H1V399H529.5V566.5H1V607H390"
              stroke="#E5F4FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
          </svg>
        </div>

        <div className="timeline__svg timeline__svg--mobile">
          <svg
            width="2"
            height="1188"
            viewBox="0 0 2 1188"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={ref => {
                this.svgMobile = ref
              }}
              d="M1 1V1187"
              stroke="#ED1C23"
              strokeWidth="0.75"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
            <path
              d="M1 1V1187"
              stroke="#E5F4FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="5 15"
            />
          </svg>
        </div>

        <ol className="timeline__container row active">
          {data.contentBlock.map((item, index) => {
            return (
              <li
                className={`timeline__item timeline__item--${index +
                  1} col col-12 col-sm-6 col-lg-3`}
                data-reveal-percent={item.revealPercentage}
                key={`timeline__item-${index}`}
              >
                <div className="timeline__counter-outer">
                  <div className="timeline__counter-inner">
                    <GatsbyImage
                      className="timeline__icon"
                      imageName={"red_pom_silhouette-small.png"}
                      alt="Pom Icon"
                      styles={{ width: "42px" }}
                    />
                    <span className="timeline__counter-number">
                      {index + 1}
                    </span>
                  </div>
                </div>
                <div className="timeline__text-container">
                  {item.title && <Title h3>{item.title}</Title>}
                  {item.date && (
                    <Text className="timeline__date">{item.date}</Text>
                  )}
                  {item.description && (
                    <Text className="timeline__description">
                      {item.description}
                    </Text>
                  )}
                </div>
              </li>
            )
          })}
        </ol>
      </>
    )
  }
}

export default Timeline
