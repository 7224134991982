import React from "react"
import { graphql } from "gatsby"

import Text from "../../wwc/text/Text/Text"
import GatsbyImage from "../../gatsby/image"

class OfferPrize extends React.Component {

  componentDidMount() {
    /* Feature detection: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners */
    let passiveIfSupported = false
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function() {
            passiveIfSupported = { passive: false }
            return null
          },
        })
      )
    } catch (err) {}

    this.prizeContainerEle = document.querySelector(".offer__image-wrapper")
    window.addEventListener("scroll", this.scrollHandler, passiveIfSupported)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler)
  }

  scrollHandler = () => {
    if (this.elementInViewport(this.prizeContainerEle)) {
      this.offerScrollHandler()
    }

    /*
		// Remove event listener for rolling pomegranate
		if (document.querySelectorAll(".offer__text-container:not(.offer__text-container--active)").length === 0 && this.getEleViewportPercentage(this.prizeContainerEle) === 1) {
			window.removeEventListener('scroll', this.scrollHandler);
		}
		*/
  }

  offerScrollHandler() {
    if(this.getEleViewportPercentage(this.prizeContainerEle) >= 0.6) {
      this.prizeContainerEle.classList.add("active");
    }
  }

  elementInViewport = el => {
    var top = el.offsetTop
    var left = el.offsetLeft
    var width = el.offsetWidth
    var height = el.offsetHeight

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    )
  }

  getEleViewportPercentage = element => {
    this.scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

    this.percentVisible =
      ((this.getTopOffset(element).top - window.scrollY - window.innerHeight) /
        element.offsetHeight) *
      -1 // math is backwards so transforming it into position numer with -1

    if (this.percentVisible <= 1 && this.percentVisible > 0) {
      return this.percentVisible
    } else if (this.percentVisible <= 0) {
      return 0
    } else {
      return 1
    }
  }

  getTopOffset = el => {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }

  render() {
    const { data } = this.props
    return (
      <div className="offer__image-wrapper">
        <GatsbyImage
          imageName={data.image.imageName}
          alt={data.image.alt}
          styles={{ width: '322px', margin: '0 auto'}}
          className="offer__image-desk"
        />
        <GatsbyImage
          imageName={data.image.imageName}
          alt={data.image.alt}
          styles={{ width: '222px', margin: '0 auto'}}
          className="offer__image-mob"
        />
        <Text className="offer__image-text">$1M</Text>
      </div>
    )
  }
}

export const query = graphql`
  fragment OfferDataQuery on PomPaydayJson {
    offer {
      title
      description
      image {
        imageName
        alt
      }
    }
  }
`
export default OfferPrize
