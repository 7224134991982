import React from "react"
import t from "prop-types"
import Section from "../../wwc/layout/Section/Section"
import Row from "../../wwc/layout/Row/Row"
import Column from "../../wwc/layout/Column/Column"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import GatsbyImage from "../../gatsby/image"
import Text from "../../wwc/text/Text/Text"
import Title from "../../wwc/text/Title/Title"

class WhatWeLookFor extends React.Component {
  static propTypes = {
    data: t.shape({
      title: t.string.isRequired,
      contentBlock: t.arrayOf(
        t.shape({
          image: t.shape({
            imageName: t.string.isRequired,
            alt: t.string.isRequired,
          }).isRequired,
          title: t.string.isRequired,
          description: t.string.isRequired,
        })
      ).isRequired,
      link: t.shape({
        label: t.string,
        url: t.string,
        cta: t.bool,
      }).isRequired,
    }).isRequired,
  }

  render() {
    const { data } = this.props

    return (
      <>
        <Section id="section-what-we-look-for">
          <Row>
            <Column className="wwlf__title">
              <Title h5>{data.title}</Title>
            </Column>
            {data.contentBlock.map((item, index) => {
              return (
                <Column key={`wwlf--${index}`} md={4}>
                  <GatsbyImage
                    imageName={item.image.imageName}
                    styles={{ maxWidth: "120px" }}
                  />
                  <Title h6>{item.title}</Title>
                  <Text>{item.description}</Text>
                </Column>
              )
            })}
            <Column className="wwlf__cta-container">
              <a
                href={data.link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn__cta--primary"
              >
                {data.link.label}
              </a>
            </Column>
          </Row>
        </Section>
      </>
    )
  }
}

export const query = graphql`
  fragment WhatWeLookForData on WhatWeLookForJson {
    whatWeLookFor {
      title
      contentBlock {
        image {
          imageName
          alt
        }
        title
        description
      }
      link {
        label
        url
        cta
      }
    }
  }
`

export default WhatWeLookFor
